export interface CenterProps {
  children?: React.ReactNode;
  className?: string;
  as?: React.ElementType;
}

export function Center({as: Cmp = 'div', children, className}: CenterProps) {
  return (
    <Cmp className={`max-w-[1280px] w-full mx-auto ${className ?? ''}`}>
      {children}
    </Cmp>
  );
}
